
import "../components/css/clear.module.css"
import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useEffect } from "react"

const ZoomPage = () => {
  
    useEffect(() => 
    {
        window.location = `https://ucdavis.zoom.us/meeting/register/tJApdOuuqTooGdaK2_d6V3Hs6sQPlum4QldM`
    })

    return <></>
}

export default ZoomPage
