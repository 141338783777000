
import "../components/css/clear.module.css"
import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useEffect } from "react"

const JoinPage = () => {
  
    useEffect(() => 
    {
        window.location = `https://discord.gg/wc6QQEc`
    })

    return <></>
}

export default JoinPage
