
import "../components/css/clear.module.css"
import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useEffect } from "react"

const WorkshopsPage = () => {
  
    useEffect(() => 
    {
        window.location = `https://www.youtube.com/playlist?list=PLI9ehpRDWdCynkp_l0kcIw0pM13NiDT55`
    })

    return <></>
}

export default WorkshopsPage
